import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import { url } from '../utile/globarVariable';
import './Style.css';
import ReactPaginate from 'react-paginate';
const AutoBackup = () => {
    const Navigate = useNavigate();
    const [sessionList, setSessionList] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [rollNumber, setRollNumber] = useState('');
    const limit = 10;
    const [activePage, setActivePage] = useState(1);

    const handleRollChange = (event) => {
        setRollNumber(event.target.value);
    };
    const [tocChecked, setTocChecked] = useState(false);


    const getSessionList = async (page) => {


        if (tocChecked) {

            const r = await axios.get(`${url}/get-Session1`);
            setSessionList(r.data);


        }
        else {
            const r = await axios.get(`${url}/get-Session?page=${activePage}&size=${limit}`);
            setSessionList(r.data.records);
            setTotalUsers(r.data.total);
        }
    };

    const getSessionFilter = async (page) => {

        setActivePage(page);
        const r = await axios.get(`${url}/get-Session?page=${page}&size=${limit}`);
        setSessionList(r.data.records);
        setTotalUsers(r.data.total);
    };

    const getSingleRecordByName = async () => {
        if (tocChecked) {
            const r = await axios.get(`${url}/Get-Session-By-Name?name=${rollNumber}&page=1&limit=10000000000`);
            setSessionList(r.data.records);
            setTotalUsers(r.data.total);
        }
        else {

        }
    };

    const getAllSession = async (value) => {




    }

    const DeleteSession = async (Id) => {
        const d = {
            Id: Id
        };
        const r = await axios.get(`${url}/Delete-Session?Id=${Id}`)
        if (r.data.Status == true) {
            alert("Session Successfully Deleted");
            getSessionList();
        } else {
            alert("Session does not Deleted");
        }
    };

    useEffect(() => {
        getSessionList();


    }, [tocChecked]);

    const totalPagesCalculator = (total, limit) => {
        const pages = [];
        for (let x = 1; x <= Math.ceil(total / limit); x++) {
            pages.push(x);
        }
        return pages;
    };

    return (
        <>
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>View Session</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a >Manage Session</a></li>
                            <li className="breadcrumb-item active">Session</li>
                        </ol>
                    </nav>
                </div>
                <div className='row'>
                    <div className='col-lg-2  mt-2'>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name='record'
                                value="All Record"
                                checked={tocChecked}
                                onChange={(e) => {
                                    setTocChecked(e.target.checked);
                                    getAllSession(e.target.value);
                                }}
                                id="flexRadioDefault2"
                            />
                            <label className="form-check-label" for="flexRadioDefault2">
                                All Record
                            </label>
                        </div>
                    </div>
                </div>
                <section className="section dashboard styled-table" style={{ overflowX: 'scroll' }}>


                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-10 col-sm-12 col-xsm-12">
                            <table className="table table-borderless" style={{ overflowX: 'scroll', width: '1300px' }}>
                                <thead>
                                    <tr>
                                        <th colSpan={'2'}><input className='form-control w-50' onChange={handleRollChange} value={rollNumber} onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                getSingleRecordByName();
                                            }
                                        }}
                                            type='text' placeholder='Enter  Title' /></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th><b>S.</b>No</th>
                                        <th>Session.</th>
                                        <th>Year</th>
                                        <th>Serial</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sessionList.map((en, n) => (
                                            <tr key={en.id}>
                                                <td>{((limit * activePage) - limit) + (n + 1)}</td>
                                                <td>{en.session}</td>
                                                <td>{en.year}</td>
                                                <td>{en.serial}</td>
                                                <td>
                                                    <i onClick={() => DeleteSession(en.id)} className='fa fa-trash' style={{ color: 'red', opacity: '0.8', cursor: 'pointer' }}></i>
                                                    <i onClick={() => Navigate(`/Get-Particular-Session/${en.id}`)} className='fa fa-edit mx-2' style={{ color: 'red', opacity: '0.8', cursor: 'pointer' }}></i>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            {
                                !tocChecked ?
                                    (
                                        <>
                                            <ReactPaginate
                                                previousLabel={"prev"}
                                                nextLabel={"next"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={totalPagesCalculator(totalUsers, limit).length}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                activeClassName={"active"}
                                                onPageChange={(data) => getSessionFilter(data.selected + 1)}
                                            />
                                        </>
                                    ) : ''
                            }
                        </div>
                    </div>

                </section>
            </main>
        </>
    );
};

export default AutoBackup;
